import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, makeStyles, Tooltip, Zoom } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../Api";
import IconButton from "@material-ui/core/IconButton";
import { ArrowLeft, ArrowRight, Fullscreen, FullscreenExit } from "@material-ui/icons";
import { ScreenFull } from "../../../Action/ScreenAction";
import { getRoutePathname } from "../../../Config/Route";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { theme } from "../../../App";
import Iframe from "./Iframe";
import { Alert, Rating } from '@material-ui/lab';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ButtonComponent from '../../../Component/ButtonComponent';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import ContentViewLearnerComponent from '../../../Component/ContentViewLearnerComponent';

function RegistrationCourse(props) {
    const dispatch = useDispatch();
    const [iFrameScale, setIFrameScale] = React.useState(1);
    const classes = useStyles(theme, iFrameScale)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [show, setShow] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [isSmallView, setSmallView] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [prevNextCourse, setPrevNextCourse] = React.useState({ prev: '', next: '' });
    const [isNextCourseLock, setIsNextCourseLock] = React.useState(false);
    const [isNextCourseLockFront, setIsNextCourseLockFront] = React.useState(false);
    const [isFinishedCourse, setIsFinishedCourse] = React.useState(false);
    const [isCourseUnblock, setIsCourseUnblock] = React.useState(false);
    const [info, setInfo] = React.useState([]);
    const [showRating, setShowRating] = React.useState(false);
    const [loadRating, setLoadRating] = React.useState(false);
    const [isGoBackRating, setIsGoBackRating] = React.useState(false);
    const [isAverage, setIsAverage] = React.useState(true);
    const [rating, setRating] = React.useState(0);
    const [title, setTitle] = React.useState('');
    const [hover, setHover] = React.useState(-1);
    const [isRatingPopup, setIsRatingPopup] = React.useState(false);

    const customIcons = {
        1: <SentimentVeryDissatisfiedIcon />,
        2: <SentimentDissatisfiedIcon />,
        3: <SentimentSatisfiedIcon />,
        4: <SentimentSatisfiedAltIcon />,
        5: <SentimentVerySatisfiedIcon />,
    };

    const goBack = (isAverage, isRatingPopup) => {
        setIsGoBackRating(true);
        if (isAverage && isRatingPopup) {
            setShowRating(true);
        } else {
            setIsFinishedCourse(false);
            setIsRatingPopup(false);
            setTimeout(() => {
                props.history.push(getRoutePathname('learner_registration', { id: props.match.params.registration }));
            }, 600);
        }
    };

    const goCourse = (course) => {
        setIsFinishedCourse(false);
        setIsRatingPopup(false);
        if (!isNextCourseLock) {
            setTimeout(() => {
                props.history.push({
                    pathname: getRoutePathname('learner_registration_course', { registration: props.match.params.registration, course: course }),
                    state: { courseType: props.location.state.courseType }
                });
            }, 800);
        }
        else {
            props.history.push(getRoutePathname('learner_registration', { id: props.match.params.registration }));
        }
    };
    const [form, setForm] = React.useState({
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });

    const handler = formHandlerInit(form, setForm);
    const saveRating = () => {
        if (!handler.checkError()) {
            setLoadRating(true)
            Api.post({
                route: 'learner_registration_course_rating',
                params: { registration: props.match.params.registration, course: props.match.params.course },
                data: { comment: handler.getData().comment, rating: rating }
            }, (response) => {
                if (response.status === 200) {
                    setLoadRating(false)
                    setIsAverage(response.data.isAverage);
                    setRating(response.data.ratingByLearner);
                    setHover(response.data.ratingByLearner ? response.data.ratingByLearner : -1)
                    setShowRating(false)
                }
                else {
                    setLoadRating(false)
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    const navCourse = (course, isRatingPopup) => {
        setIsGoBackRating(false);
        setIsRatingPopup(false);
        setIsFinishedCourse(false);
        dispatch(LinearProgressMainLayoutActivate());
        Api.get({
            route: 'learner_registration_course_nav',
            params: {
                registration: props.match.params.registration,
                course: props.match.params.course
            }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    setIsNextCourseLock(!response.data.isNextCourseUnlock);
                    if (response.data.isNextCourseUnlock) {
                        (isAverage && isRatingPopup) ? setShowRating(true) :
                            props.history.push({
                                pathname: getRoutePathname('learner_registration_course', { registration: props.match.params.registration, course: course }),
                                state: { courseType: props.location.state.courseType }
                            });
                    }
                    else {
                        setIsNextCourseLockFront(true);
                        setTimeout(() => {
                            setIsNextCourseLockFront(false);
                        }, 2000);
                    }

                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }

    // Iframe Scale
    const generateIFrameScale = () => {

        if (!isScale) return;

        let orientation = window.screen.orientation && window.screen.orientation.angle === 90;

        if (screenReducer.full) {
            let ratioWidth = window.innerWidth / iFrameWidth;
            let ratioHeight = (window.innerHeight - (screenReducer.full ? 0 : 58)) / iFrameHeight;

            if (orientation) {
                if ((iFrameWidth / iFrameHeight) < (window.innerWidth / window.innerHeight)) {
                    setIFrameScale(ratioHeight);
                } else {
                    setIFrameScale(ratioWidth);
                }
            } else {
                if ((iFrameWidth / iFrameHeight) < (window.innerWidth / window.innerHeight)) {
                    setIFrameScale(ratioHeight);
                } else {
                    setIFrameScale(ratioWidth);
                }
            }
        } else {
            let ratioWidth = window.innerWidth / iFrameWidth;
            let ratioHeight = (window.innerHeight - (window.innerHeight / 3)) / iFrameHeight;


            if (orientation) {
                if (window.innerHeight < iFrameHeight) {
                    setIFrameScale(ratioHeight);
                } else {
                    setIFrameScale(1);
                }
            } else {
                let r = 1;
                if (window.innerHeight < (iFrameHeight + 200)) {
                    r = ratioHeight;
                }
                if (window.innerWidth < iFrameWidth && ratioHeight > ratioWidth) {
                    r = ratioWidth;
                }

                setIFrameScale(r);
            }
        }
    };
    React.useEffect(generateIFrameScale, [screenReducer.full, screenReducer.size, screenReducer.orientation]);
    React.useEffect(() => {
        if (screenReducer.orientation && isScale) {
            setTimeout(() => dispatch(ScreenFull(true)), 50);
        }
    }, [screenReducer.orientation]);

    let isScale = true;
    let styleContent = {};
    let iFrameWidth = 0;
    let iFrameHeight = 0;

    if (props.location.state) {
        if (props.location.state.courseType === 4 || props.location.state.courseType === 7) {
            iFrameWidth = 1240;
            iFrameHeight = '100%';
            styleContent = { maxWidth: iFrameWidth, width: '100%', height: '100%', zIndex: 1, position: 'relative' };
            isScale = false;
        } else if (props.location.state.courseType === 6) {
            iFrameWidth = 1240;
            iFrameHeight = '100%';
            styleContent = { maxWidth: iFrameWidth, width: '100%', height: iFrameHeight, zIndex: 1, position: 'relative', transform: 'scale(' + iFrameScale + ')' };
            isScale = true;
        } else {
            iFrameWidth = 920;
            iFrameHeight = 640;
            styleContent = { width: iFrameWidth, height: iFrameHeight, zIndex: 101, position: 'relative', transform: 'scale(' + iFrameScale + ')' };
            isScale = true;
        }
    }

    React.useEffect(() => {
        if (props.match.params.registration && props.match.params.course) {
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                route: 'learner_registration_course_get',
                params: {
                    registration: props.match.params.registration,
                    course: props.match.params.course
                }
            },
                (response) => {
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        setInfo(response.data);
                        setTitle(response.data.name);
                        setRating(response.data.rating.ratingByLearner ?? 5);
                        setHover(response.data.rating.ratingByLearner ? response.data.rating.ratingByLearner : -1);
                        setIsAverage(response.data.rating.isAverage);
                        setPrevNextCourse({
                            prev: response.data.prevCourse,
                            next: response.data.nextCourse
                        });

                        if (isFinishedCourse === true && !response.data.nextCourse) {
                            setIsGoBackRating(false);
                            if (response.data.rating.isAverage && isRatingPopup) {
                                setShowRating(true);
                            } else {
                                setTimeout(() => {
                                    props.history.push(getRoutePathname('learner_registration', { id: props.match.params.registration }));
                                }, 100);
                            }
                        }
                        else if (props.location.state.courseType === 4 && isFinishedCourse === true) {
                            setIsGoBackRating(false);
                            if (response.data.rating.isAverage && isRatingPopup) {
                                setShowRating(true);
                            } else {
                                setTimeout(() => {
                                    goCourse(response.data.nextCourse);
                                }, 100);
                            }
                        }
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('learner_registration', { id: props.match.params.registration }));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        props.history.push(getRoutePathname('learner_registration', { id: props.match.params.registration }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            Api.get({
                route: 'learner_registration_course_nav',
                params: {
                    registration: props.match.params.registration,
                    course: props.match.params.course
                }
            }, (response) => {
                if (response.status === 200) {
                    setIsCourseUnblock(response.data.isNextCourseUnlock);
                    if (!response.data.isNextCourseUnlock) {
                        window.onbeforeunload = () => true;
                    } else window.onbeforeunload = () => undefined;
                }
            });
            setIsUnblock(false);
        }
    }, [props.match.params.course, isFinishedCourse]);

    React.useEffect(() => {
        setBreadcrumbs({
            currentPage: title ?? null,
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                { path: getRoutePathname('learner_registration', { id: props.match.params.registration }), label: 'Sommaire' }
            ],
            html: <div style={{ display: 'flex', gap: 5, justifyContent: 'right', flexWrap: 'wrap' }} >
                {(!screenReducer.full && info.length != 0 && parseInt(info.financeType) !== 6) &&
                    <StayTime duration={info.duration} timeSpent={info.timeSpent} {...props} />
                }
                <NextCourse prevNextCourse={prevNextCourse} navCourse={navCourse} {...props} />
            </div>
        });
    }, [props.match.params.course, screenReducer, info, title, prevNextCourse]);

    const courseFinished = () => {
        setIsRatingPopup(true)
        setIsFinishedCourse(true);
    };

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);
    React.useEffect(() => {
        const handleEvent = () => {
            goBack(isAverage, true);
        };
        window.addEventListener("fromItemToPortail", handleEvent);
        return () => window.removeEventListener("fromItemToPortail", handleEvent)
    }, [isAverage]);
    React.useEffect(() => {
        window.addEventListener("isCourseFinished", courseFinished);
        return () => window.removeEventListener("isCourseFinished", courseFinished)
    }, []);

    React.useEffect(() => {
        window.addEventListener('message', function (event) {
            if (event.origin === 'https://data.santeformapro.com' && event.data === 'isCourseFinished') {
                courseFinished();
            }
        });
        return () => {
            window.removeEventListener('message', function (event) {
                if (event.origin === 'https://data.santeformapro.com' && event.data === 'isCourseFinished') {
                    courseFinished();
                }
            });
        };
    }, []);

    React.useEffect(() => {
        if (!props.location.state) goBack(isAverage, false);
    }, [props.location.state]);

    React.useEffect(() => {
        if (props.location.state.courseType === 5 || props.location.state.courseType === 6) {
            Api.post({
                route: 'learner_registration_course_score',
                params: {
                    registration: props.match.params.registration,
                    course: props.match.params.course
                },
                data: {
                    score: 100
                }
            },
                (response) => {
                    if (response.status === 200) {
                        setIsFinishedCourse(true);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }, []);

    const [showDialog, setShowDialog] = React.useState(false);
    const [nextPath, setNextPath] = React.useState(null);
    const [isUnblock, setIsUnblock] = React.useState(false);

    React.useEffect(() => {

        if (!isNextCourseLock) {
            const unblock = props.history.block((tx) => {
                const nextLocation = {
                    ...tx,
                    retry() {
                        unblock();
                        tx.retry();
                    },
                };
                setNextPath(nextLocation.pathname)
                if (isFinishedCourse) {
                    setShowDialog(false);
                    return
                }
                if ((nextLocation.pathname !== props.history.location.pathname)) {
                    dispatch(ScreenFull(false));
                }
                if ((!isUnblock && !isCourseUnblock && !isFinishedCourse && (nextLocation.pathname !== props.history.location.pathname) && props.location.state.courseType !== 7)) {
                    setShowDialog(true);
                    return false;
                }
            });
            return unblock;
        }

    }, [isUnblock, isCourseUnblock, isFinishedCourse]);


    return (
        <ContentViewLearnerComponent className={screenReducer.full && classes.contentViewLearner} loading={loading} breadcrumbs={breadcrumbs} onChildrenStyle={true}  {...props}>
            <div style={{ textAlign: 'center', margin: 'auto', height: '100%' }}>
                <Fade in={show} {...{ timeout: 1000 }}>
                    <div style={{ textAlign: 'center', margin: 'auto', alignItems: 'center', justifyContent: 'center', height: '100%', display: 'flex', flexDirection: 'column', }}>
                        {screenReducer.full && <div style={{ height: '100%', width: '100%', top: 0, backgroundColor: '#000', position: 'absolute', zIndex: 100 }} />}

                        <Fade in={isNextCourseLockFront} {...{ timeout: 1000 }} style={{ position: 'absolute', top: '50%', transform: 'translate(-50%,-50%)', left: '50%', zIndex: 110 }}>
                            <Alert severity="info">
                                {parseInt(info.financeType) === 1 ? 'Pour accéder au cours suivant, vous devez terminer le cours actuel et respecter le temps requis.' : 'Terminez le cours actuel pour passer au suivant.'}
                            </Alert>
                        </Fade>

                        <div className={`${classes.styleIframe} ${screenReducer.full && classes.styleIframeFull}`} style={styleContent}>
                            {
                                isScale && <>
                                    {
                                        screenReducer.full ?
                                            <IconButton component="span" className={classes.btnFullScreen} onClick={() => dispatch(ScreenFull(false))}>
                                                <FullscreenExit className={classes.btnFullScreenIcon} />
                                            </IconButton> :
                                            <IconButton component="span" className={classes.btnFullScreen} onClick={() => dispatch(ScreenFull(true))}>
                                                <Fullscreen className={classes.btnFullScreenIcon} />
                                            </IconButton>
                                    }
                                </>
                            }
                            <Iframe
                                id={'Iframe'}
                                registration={props.match.params.registration}
                                course={props.match.params.course}
                                courseType={props.location.state.courseType}
                            />
                        </div>
                    </div>
                </Fade>
            </div>
            <Dialog className={classes.dialog} open={showRating} maxWidth={'xl'} TransitionComponent={Transition}>
                <DialogContent>
                    {/*<div className='customIcons-root'>{customIcons[hover !== -1 ? hover : (rating ? rating : 1)]}</div>*/}
                    <div className='customIcons-root'>{customIcons[5]}</div>
                    {/*<h1 style={{ margin: 0, }}>Donnez une note</h1>*/}
                    <p style={{ margin: 0, padding: '10px 0', }}><span>Donnez votre avis en quelques clics !</span><br /> Évaluez ce cours de 0 à 5 étoiles.</p>
                    <Rating
                        name="hover-feedback"
                        value={rating}
                        precision={1}
                        style={{ padding: '0 0 10px 0', }}
                        onChange={(event, newValue) => {
                            setRating(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                            setHover(newHover);
                        }}
                        disabled={loadRating}
                    />
                    {/*<TextFieldComponent name={'comment'} handler={handler} multiline={true} />*/}
                </DialogContent>
                <DialogActions style={{ width: 'fit-content', margin: 'auto' }}>
                    <ButtonComponent color={theme.colorPrimary} label={'valider'} onClick={() => {
                        saveRating();
                        isGoBackRating ? goBack(isAverage, false) : navCourse(prevNextCourse.next, false)
                    }} disabled={loadRating} />
                    <ButtonComponent color={'#5E6E82'} label={'Quitter'} onClick={() => {
                        setShowRating(false);
                        isGoBackRating ? goBack(isAverage, false) : navCourse(prevNextCourse.next, false);
                    }} disabled={loadRating} />
                    {/*<ButtonComponent color={'#5E6E82'} label={isGoBackRating ? 'retour au sommaire' : 'cours suivant'} onClick={() => {
                        setShowRating(false);
                        isGoBackRating ? goBack(isAverage, false) : navCourse(prevNextCourse.next, false);
                    }} disabled={loadRating} />*/}
                </DialogActions>
                <p style={{ margin: 0, padding: '0px 10px 10px', fontSize: 10 }}>cliquez sur <b>valider</b> pour enregistrer et {isGoBackRating ? 'retourner au sommaire' : 'passer au cours suivant'}</p>
            </Dialog>
            <SweetAlert
                show={showDialog}
                title={'Quitter le cours'}
                text={'Êtes-vous sûr de vouloir quitter cette page ? Si oui, toutes les progressions de ce cours seront perdues.'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setIsUnblock(true)
                    setShowDialog(false)
                    props.history.push({
                        pathname: nextPath,
                        state: { courseType: props.location.state.courseType }
                    });
                    dispatch(ScreenFull(false));
                    window.onbeforeunload = () => undefined;
                }}
                onCancel={() => { setShowDialog(false) }}
            />
        </ContentViewLearnerComponent>
    );
}

const NextCourse = (props) => {
    const classes = useStyles(theme, null)();
    const prevNextCourse = props.prevNextCourse;
    return (
        <Box style={{ display: 'flex', gap: 5, justifyContent: 'right', flexWrap: 'wrap' }}>
            {prevNextCourse.prev && <Tooltip title="Cours précédent" aria-label="prev" arrow>
                <IconButton style={{ backgroundColor: 'white' }} className={classes.buttonAction} aria-label="prev"
                    onClick={() => {
                        props.history.push({
                            pathname: getRoutePathname('learner_registration_course', { registration: props.match.params.registration, course: prevNextCourse.prev }),
                            state: { courseType: props.location.state.courseType }
                        });
                    }}>
                    <ArrowLeft style={{ fontSize: 25 }} className={classes.buttonActionIcon} />
                    <span style={{ fontSize: 13 }}>Cours précédent</span>
                </IconButton>
            </Tooltip>}
            {prevNextCourse.next && <Tooltip title="Cours suivant" aria-label="next" arrow>
                <IconButton style={{ backgroundColor: 'white' }} className={classes.buttonAction} aria-label="next"
                    onClick={() => props.navCourse(prevNextCourse.next, true)}>
                    <span style={{ fontSize: 13 }}>Cours suivant</span>
                    <ArrowRight style={{ fontSize: 25 }} className={classes.buttonActionIcon} />
                </IconButton>
            </Tooltip>}
        </Box>
    )
}

const StayTime = (props) => {
    const [initTime, setInitTime] = React.useState(0);
    const minutes = Math.floor(initTime / 60);
    const remainingSeconds = initTime % 60;
    const seconds = props.duration - props.timeSpent;

    React.useEffect(() => {
        const interval = setInterval(() => {
            setInitTime(initTimes => {
                const updatedTime = initTimes - 1;
                if (updatedTime <= 0) {
                    clearInterval(interval);
                }
                return updatedTime;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, [props.match.params.course, seconds]);

    React.useEffect(() => {
        setInitTime(seconds)
    }, [props.match.params.course, seconds]);

    return (
        <span style={{ background: ((minutes <= 0) && (remainingSeconds <= 0)) ? '#6FD08C' : theme.colorPrimary, fontWeight: 'bold', color: '#FFF', padding: '6px 15px', borderRadius: 5, fontSize: 11, display: 'flex', gap: 5, alignItems: 'center' }}>
            {((minutes <= 0) && (remainingSeconds <= 0)) ? 'Temps validé' : minutes + ' min ' + remainingSeconds + 's restant'} <AccessTimeIcon style={{ fontSize: 16 }} />
        </span>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />
});

const useStyles = (theme, iFrameScale) => makeStyles(({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(-15%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '15% !important'
        }
    },
    shadowBox: {
        textAlign: 'center',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 5px 20px 0px rgba(0,0,0,0.25)',
    },
    btnFullScreen: {
        right: 60,
        top: 0,
        position: 'absolute',
        padding: 10,
        margin: 0,
        marginRight: 12,
        color: '#FFFFFF',
        zIndex: 3
    },
    btnFullScreenIcon: {
        fontSize: 30
    },
    contentViewLearner: {
        '& .main-root': { position: 'initial !important' }
    },
    buttonAction: {
        padding: '5px 10px',
        margin: 0,
        borderRadius: 5,
    },
    buttonActionIcon: {
        fontSize: 18,
        opacity: 1,
        '& g > path': {
            stroke: 'rgba(0, 0, 0, 0.6)'
        }
    },
    styleIframe: {
        '& iframe': {
            marginTop: '0 !important',
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 5px 0px !important',
            borderRadius: '5px !important',
        }
    },
    styleIframeFull: {
        position: 'absolute !important',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%) scale(${iFrameScale}) !important`
    },
    dialog: {
        '& .MuiDialog-paper, .MuiDialogContent-root': {
            overflow: 'initial',
            textAlign: 'center',
        },
        '& div.customIcons-root': {
            '& svg': {
                display: 'block',
                width: 'auto',
                margin: '-44px auto auto auto',
                background: '#FFF',
                height: 50,
                borderRadius: 100
            },
        },
        '& .MuiRating-root': {
            margin: 'auto',
            width: 'fit-content',
            fontSize: 35,
            display: 'flex',
            color: theme.colorPrimary,
        }
    },
    stateCoure: {
        border: 'solid 1px #DADADA',
        borderRadius: 5,
        background: '#FFF',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 5,
        zIndex: 101,
        bottom: 10,
        left: 10,
        margin: '20px auto',
        '& >div': {
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            margin: 'auto',
            gap: 5,
            '& p': {
                padding: 0,
                margin: 0,
                fontWeight: 'bold',
            }
        },
        '& .MuiRating-root': {
            color: theme.colorPrimary,
        }
    },
}));

export default RegistrationCourse;
